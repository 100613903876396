
import Http from './Http'

export default {
    async getTransactions(data) {
        return await Http.get(`/payment/transactions?${data}`)
    },
    async getTransaction(transactionId) {
        return await Http.get(`/payment/transactions/${transactionId}`)
    },
    async updateTransaction(data) {
        return await Http.patch(`/payment/transactions`, data)
    },
    async createRefund(data) {
        return await Http.post(`/payment/refund`, data)
    },
    async getAllPayouts(){
        return await Http.get(`/payment/all_payouts`)
    },
    async getTransactionSummary(data){
        return await Http.get(`/payment/transactions_summary?${data}`)
    },
    async getPayoutSummary(data){
        return await Http.get(`/payment/payout_summary?${data}`)
    },
    async getPayoutsByType(data){
        return await Http.get(`/payment/payouts_by_type?${data}`)
    },
    async getTransactionsNotInEwallet(data){
        return await Http.get(`/payment/transactions_not_in_ewallet?${data}`)
    },
    async getTransactionsNotInSro(data){
        return await Http.get(`/payment/transactions_not_in_sro?${data}`)
    } ,
    async getPayoutRefunds(data){
        return await Http.get(`/payment/transactions_refund?${data}`)
    } ,
}


